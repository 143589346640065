import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import get from 'lodash.get';

import * as utils from '@utils';
import { colors, dimensions, mixins, respondFrom, breakpoints, css } from '@styles';
import removeProductIcon from '@assets/svg/trash.svg';
import { BasketProduct } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import { ConfiguratorBasket } from '@store/configurator/types';
import { Funding } from '@store/content/types';
import { PriceListPaymentTypes } from '@graphql/types';

const Wrapper = styled.div<StyleProps>`
  margin-bottom: 20px;
  padding: 10px 2px;
  position: relative;

  ${props => (props.isClickable && !props.border ? mixins.borderOnHover : null)}
  border: 2px solid
    ${props => (props.border ? colors.blue.default : 'transparent')};

  ${respondFrom(
  breakpoints.lg,
  css`
      :not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #848484;
        }
      }
    `
)}
`;

const GhostElement = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const ProductRemoveButton = styled.button`
  position: absolute;
  right: 0;
  z-index: 2;
  padding: 5px;
  margin-right: -5px;
`;

const ProductBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  span {
    color: ${colors.text.default};
    font-size: ${dimensions.fontSize.large}px;
  }

  button {
    background: transparent;
    cursor: pointer;
    border: 0;
    line-height: 0.5;

    &:focus {
      outline: none;
    }
  }
`;

const ProductName = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 23px;
  position: relative;

  span {
    font-size: 14px;
    font-weight: bold;
  }
  ${respondFrom(
  breakpoints.lg,
  css`
      justify-content: flex-start;
      span {
        font-weight: 500;
      }
    `
)}
`;

const PriceProduct = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 23px;

  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    font-size: 13px;
    line-height: 1.15em;
    font-weight: 300;
  }

  img,
  svg {
    height: 14px;
    width: 14px;
    position: relative;
    top: 2px;
  }

  &.hide-on-mobile {
    display: none;

    ${respondFrom(
      breakpoints.lg,
      css`
            display: flex;
          `
    )}
  }
`;

const StyledImage = styled(Image)`
  display: none !important;
  ${respondFrom(
  breakpoints.lg,
  css`
      display: inline-block !important;
      align-self: center;
      margin-right: 10px;
      max-width: 107px;
      max-height: 71px;
    `
)}
`;

export const RemoveProduct = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -13px;
  margin: auto;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 2;
  padding: 12px 20px;

  &::before,
  &::after {
    content: '';
    width: 14px;
    height: 1px;
    background-color: ${colors.text.default};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    ${mixins.transitionDefault};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  ${respondFrom(
  breakpoints.lg,
  css`
      &:hover {
        &::before,
        &::after {
          background-color: ${colors.green.dark};
        }
      }
    `
)}
`;

const ProductNameIndex = styled.span`
  ${respondFrom(
  breakpoints.lg,
  css`
      display: none;
    `
)}
`;

const ProductNamePrice = styled.span`
  font-size: 13px !important;
  font-weight: 300 !important;
  ${respondFrom(
  breakpoints.lg,
  css`
      display: none;
    `
)}
`;

interface Props extends StyleProps {
  index: number;
  activeStep: string;
  product: BasketProduct;
  configuratorActions: typeof ConfiguratorActions;
  pageSettings: ConfiguratorSettings;
  basket: ConfiguratorBasket;
  allFundings: Funding[];
  currentProductIndex: number;
  paymentType: string;
}

interface StyleProps {
  border: boolean;
  isClickable: boolean;
}

class ShoppingProduct extends React.Component<Props> {
  onRemove = (index: number) => {
    this.props.configuratorActions.basketRemoveProduct(index);
    this.props.configuratorActions.setCurrentProductIndex(0);

    const productPrice = utils.prices.getProductPrice({ 
        product: this.props.product, 
        paymentType: 'cash'
    });
    const price = productPrice ? productPrice.iposFee : 0;

    const { name, id, currentColor } = this.props.product;
    const variant = currentColor ? currentColor.name : '';
    utils.setDatalayerRemoveFromCart(name, id, price, 'Device', variant);
  };

  onAccessoryRemove = (accessory, productIndex) => {
    this.props.configuratorActions.setCurrentProductIndex(productIndex);
    this.props.configuratorActions.basketRemoveAccessory({ accessory });
  };

  onColorRemove = (product, productIndex) => {
    this.props.configuratorActions.setCurrentProductIndex(productIndex);
    this.props.configuratorActions.basketSetCurrentColor(product.availableColors[0]);
  };

  onProductClick = index => {
    const { activeStep, configuratorActions } = this.props;
    if (
      (activeStep === 'colors' && utils.productHasAvailableColors(this.props.product)) ||
      (activeStep === 'accessories' && utils.productHasAvailableAccessories(this.props.product))
    ) {
      configuratorActions.setCurrentProductIndex(index);
    }
  };

  render() {
    const { product, index, border, isClickable, paymentType, basket } = this.props;
    const image_ = product.currentColor
      ? get(product.currentColor.image, 'childImageSharp')
      : get(product.image, 'childImageSharp');
    const pPole = this.props.pageSettings.field_proste_pola;
    const fundingPriceListGroup = utils.getFundingPriceListGroup(basket);
    const productPrice = utils.prices.getProductPrice({ 
        product, 
        paymentType,
        priceListGroup: fundingPriceListGroup
      });
    const fiscalizationPrice = utils.prices.getFiscalizationPrice({
      fiscalization: basket.fiscalization,
      priceListGroup: fundingPriceListGroup
    });

    const isProductPriceCashAndLease =
      productPrice &&
      productPrice.lease &&
      productPrice.paymentType === PriceListPaymentTypes.casch;

    const leaseText = `${pPole[186].charAt(0).toUpperCase() + pPole[186].slice(1)} ${product.productType === 'terminal' ? pPole[190] : pPole[191]}`;

    const leaseProductLabel = pPole
      ? isProductPriceCashAndLease
        ? leaseText
        : !!fundingPriceListGroup
        ? pPole[162]
        : pPole[25]
      : '';

    return (
      <Wrapper border={border} isClickable={isClickable}>
        <GhostElement onClick={() => this.onProductClick(index)} />
        <ProductBox>
          <ProductName>
            {image_ && <StyledImage {...image_} imgStyle={{ objectFit: 'contain' }} />}
            <span>
              <ProductNameIndex>{index + 1}. </ProductNameIndex>
              {product.name}
            </span>
            {
              (productPrice && Object.keys(productPrice).length > 0) &&
              <ProductNamePrice>
                {
                  (paymentType === "lease")
                  ? `${utils.formatCurrency(!!fundingPriceListGroup && productPrice.reducedFee !== undefined ? productPrice.reducedFee : productPrice.iposFee)} / ${pPole ? pPole[156] : ""}`
                  : utils.formatCurrency(productPrice.iposFee)
                }
              </ProductNamePrice>
            }
            <ProductRemoveButton type="button" onClick={() => this.onRemove(index)}>
              <img src={removeProductIcon} alt="remove product" />
            </ProductRemoveButton>
          </ProductName>
        </ProductBox>
        {
          (productPrice && Object.keys(productPrice).length > 0) &&
          <PriceProduct className="hide-on-mobile">
          <span>
            {leaseProductLabel}
          </span>
            <span>
              {
                (paymentType === "lease")
                  ? `${utils.formatCurrency(!!fundingPriceListGroup && productPrice.reducedFee !== undefined ? productPrice.reducedFee : productPrice.iposFee)} / ${pPole ? pPole[156] : ""}`
                  : isProductPriceCashAndLease ? utils.formatCurrency(productPrice.iposFee) + (pPole ? ` / ${pPole[156]}` : '') : utils.formatCurrency(productPrice.iposFee)
              }
            </span>
          </PriceProduct>
        }
        {
          (productPrice && productPrice.activationPrice) &&
          <PriceProduct>
            <span>{pPole ? (isProductPriceCashAndLease ? pPole[184] : pPole[167]) : ''}</span>
            <span>{utils.formatCurrency(productPrice.activationPrice)}</span>
          </PriceProduct>
        }
        {
          fiscalizationPrice && 
          <PriceProduct>
            <span>{pPole ? pPole[26] : ''}</span>
            <span>{utils.formatCurrency(fiscalizationPrice.iposFee)}</span>
          </PriceProduct>
        }
        {product.currentColor && product.currentColor.price > 0 && (
          <PriceProduct>
            <span>
              {pPole ? pPole[27] : ''} ({product.currentColor.name})
            </span>
            <span>{product.currentColor.price} zł</span>
            <RemoveProduct onClick={() => this.onColorRemove(product, this.props.index)} />
          </PriceProduct>
        )}
        {product.accessories.map((acc, index) => (
          <PriceProduct key={index}>
            <span>{acc.name}</span>
            <span>{utils.formatCurrency(acc.price)}</span>
            <RemoveProduct onClick={() => this.onAccessoryRemove(acc, this.props.index)} />
          </PriceProduct>
        ))}
      </Wrapper>
    );
  }
}

export default ShoppingProduct;
